<template>
  <div>
    <div class="box_top" style="width:100%;">
      <div class="flex" style="width: 60%;">
        <templateHeader title="人口特征和经济发展水平主要情况" />

        <!-- <div style="margin-left: 10px;">
          <el-select size="mini" v-model="OrgId" @change="changeOrgPids" placeholder="四川省">
            <el-option v-for="item in treeData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>

        <div style="margin-left: 10px;">
          <el-button size="mini" plain icon="el-icon-search" @click="search"></el-button>
        </div>

        <div style="margin-left: 10px;">
          <el-button size="mini" plain icon="el-icon-refresh-right" @click="reset"></el-button>
        </div> -->
      </div>
      <div class="export_box">
        <el-button type="primary" size="mini" plain icon="el-icon-download" @click="exportExcel">导出</el-button>
        <el-button type="primary" size="mini" icon="el-icon-upload2" @click="importExcel">导入</el-button>
      </div>
    </div>

    <el-row>
      <el-col :span="6">
        <div class="box_item1">
          <templateHeader style="margin-bottom: 4px;" title="全省生成总值排名（位）" />
          <div class="box_item1_list" v-for="(item, index) in listData1" :key="item.id">
            <div class="item1_list_left" v-if="index == 0">{{ year ? year - 1 : '--' }}年</div>
            <div class="item1_list_left" v-if="index == 1">{{ year ? year : '--' }}年</div>
            <div class="item1_list_right">{{ item.gdpRankingInProvince }}</div>
          </div>

          <div class="box_item1_list" v-if="listData1.length == 1">
            <div class="item1_list_left">{{ year ? year : '--' }}年</div>
            <div class="item1_list_right">{{ '--' }}</div>
          </div>

          <div class="box_item1_list">
            <div class="item1_list_left">较上一年增减</div>
            <div
              v-if="listData1[1]?.gdpRankingInProvince > listData1[0]?.gdpRankingInProvince"
              style="color: red;"
              class="item1_list_right"
            >
              {{
                Number.isNaN(listData1[1]?.gdpRankingInProvince - listData1[0]?.gdpRankingInProvince) == true
                  ? '--'
                  : listData1[1]?.gdpRankingInProvince - listData1[0]?.gdpRankingInProvince
              }}
            </div>
            <div v-else style="color: green;" class="item1_list_right">
              {{
                Number.isNaN(listData1[1]?.gdpRankingInProvince - listData1[0]?.gdpRankingInProvince) == true
                  ? '--'
                  : listData1[1]?.gdpRankingInProvince - listData1[0]?.gdpRankingInProvince
              }}
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="box_item1">
          <templateHeader style="margin-bottom: 4px;" title="设置行政区、市、区（位）" />
          <div class="box_item1_list" v-for="(item, index) in listData1" :key="item.id">
            <div class="item1_list_left" v-if="index == 0">{{ year ? year - 1 : '--' }}年</div>
            <div class="item1_list_left" v-if="index == 1">{{ year ? year : '--' }}年</div>
            <div class="item1_list_right">{{ item.countys }}</div>
          </div>

          <div class="box_item1_list" v-if="listData1.length == 1">
            <div class="item1_list_left">{{ year ? year : '--' }}年</div>
            <div class="item1_list_right">{{ '--' }}</div>
          </div>

          <div class="box_item1_list">
            <div class="item1_list_left">较上一年增减</div>
            <div v-if="listData1[1]?.countys > listData1[0]?.countys" style="color: red;" class="item1_list_right">
              {{
                Number.isNaN(listData1[1]?.countys - listData1[0]?.countys) == true
                  ? '--'
                  : listData1[1]?.countys - listData1[0]?.countys
              }}
            </div>
            <div v-else style="color: green;" class="item1_list_right">
              {{
                Number.isNaN(listData1[1]?.countys - listData1[0]?.countys) == true
                  ? '--'
                  : listData1[1]?.countys - listData1[0]?.countys
              }}
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="box_item1">
          <templateHeader style="margin-bottom: 4px;" title="涉及行政区乡、镇、街道（个）" />
          <div class="box_item1_list" v-for="(item, index) in listData1" :key="item.id">
            <div class="item1_list_left" v-if="index == 0">{{ year ? year - 1 : '--' }}年</div>
            <div class="item1_list_left" v-if="index == 1">{{ year ? year : '--' }}年</div>
            <div class="item1_list_right">{{ item.townships }}</div>
          </div>

          <div class="box_item1_list" v-if="listData1.length == 1">
            <div class="item1_list_left">{{ year ? year : '--' }}年</div>
            <div class="item1_list_right">{{ '--' }}</div>
          </div>

          <div class="box_item1_list">
            <div class="item1_list_left">较上一年增减</div>
            <div v-if="listData1[1]?.townships > listData1[0]?.townships" style="color: red;" class="item1_list_right">
              {{
                Number.isNaN(listData1[1]?.townships - listData1[0]?.townships) == true
                  ? '--'
                  : listData1[1]?.townships - listData1[0]?.townships
              }}
            </div>
            <div v-else style="color: green;" class="item1_list_right">
              {{
                Number.isNaN(listData1[1]?.townships - listData1[0]?.townships) == true
                  ? '--'
                  : listData1[1]?.townships - listData1[0]?.townships
              }}
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="box_item1">
          <templateHeader style="margin-bottom: 4px;" title="涉及行政区行政村（个）" />
          <div class="box_item1_list" v-for="(item, index) in listData1" :key="item.id">
            <div class="item1_list_left" v-if="index == 0">{{ year ? year - 1 : '--' }}年</div>
            <div class="item1_list_left" v-if="index == 1">{{ year ? year : '--' }}年</div>
            <div class="item1_list_right">{{ item.communitys }}</div>
          </div>

          <div class="box_item1_list" v-if="listData1.length == 1">
            <div class="item1_list_left">{{ year ? year : '--' }}年</div>
            <div class="item1_list_right">{{ '--' }}</div>
          </div>

          <div class="box_item1_list">
            <div class="item1_list_left">较上一年增减</div>
            <div
              v-if="listData1[1]?.communitys > listData1[0]?.communitys"
              style="color: red;"
              class="item1_list_right"
            >
              {{
                Number.isNaN(listData1[1]?.communitys - listData1[0]?.communitys) == true
                  ? '--'
                  : listData1[1]?.communitys - listData1[0]?.communitys
              }}
            </div>
            <div v-else style="color: green;" class="item1_list_right">
              {{
                Number.isNaN(listData1[1]?.communitys - listData1[0]?.communitys) == true
                  ? '--'
                  : listData1[1]?.communitys - listData1[0]?.communitys
              }}
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="12">
        <div class="box_item2" id="box_echarts1">
          <templateHeader style="margin-bottom: 4px;" title="三大产业占总值比重" />
          <div id="echarts1"></div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="box_item2" id="box_echarts2">
          <templateHeader style="margin-bottom: 4px;" title="人均住房面积" />
          <div id="echarts2"></div>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="12">
        <div class="box_item3" id="box_echarts3">
          <templateHeader style="margin-bottom: 4px;" title="人口主要情况" />
          <div id="echarts3"></div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="box_item3" id="box_echarts4">
          <templateHeader style="margin-bottom: 4px;" title="人口经济情况" />
          <div id="echarts4"></div>
        </div>
      </el-col>
    </el-row>

    <tempalteImport ref="tempalteImport" @refreshTable="refreshTable" />
  </div>
</template>

<script>
import templateHeader from '@/components/templateView/templateHeader.vue'
import tempalteImport from '@/components/templateView/tempalteImport.vue'
import elementResizeDetectorMaker from 'element-resize-detector'
import {
  _PopulationDataEconomic,
  _PopulationExport
} from '@/api/modular/postImmigrationService/HomePage/socialDevelopment.js'
import { _getLevelRegion } from '@/api/keepHome'
export default {
  name: 'HcYmhfIndex',

  data() {
    return {
      listData1: [],
      chart1: null,
      chart2: null,
      chart3: null,
      chart4: null,
      year: null,
      years: [],
      treeData: [],
      OrgId: null,
      OrgPids: null,
      downloadObj: [],
      dck: []
    }
  },
  components: {
    templateHeader,
    tempalteImport
  },
  methods: {
    init() {
      this.getLevelRegion()
      this.getPage()
    },

    getLevelRegion(val) {
      let params = {
        startLevel: 2,
        endLevel: 2,
        inclusionLevel: false
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.treeData = res.data
        }
      })
    },
    setYears(value, years) {
      this.year = value
      this.years = years
      this.init()
    },
    refreshTable() {
      this.getPage()
    },
    changeOrgPids(value) {
      let obj = this.treeData.find(ele => ele.id == value)
      this.OrgId = value
      this.OrgPids = obj?.pids
      // this.$emit('changeFaOrgId', this.OrgId)
    },

    search() {
      this.$emit('changeFaOrgId', this.OrgId)
      this.getPage()
    },
    reset() {
      let nowYear = new Date().getFullYear()
      if (nowYear <= 2023) {
        this.year = nowYear - 1
      } else {
        this.year = nowYear - 2
      }

      this.OrgId = null
      this.projectTypeId = null

      this.$emit('changeFaOrgId', null)
      this.getPage()
    },

    receiveId(value) {
      let obj = this.treeData.find(ele => ele.id == value)
      // this.treeData = [...this.treeData]
      if (value) {
        this.dck = JSON.parse(JSON.stringify([value]))
      } else {
        this.dck = []
      }
      this.downloadObj = obj
      this.OrgId = value ? value : null
      this.OrgPids = obj?.pids

      this.getPage()
    },

    getPage() {
      let params = {
        year: this.year,
        OrgId: this.OrgId,
        OrgPids: this.OrgPids
      }
      _PopulationDataEconomic(params).then(res => {
        if (res.code == 200) {
          this.listData1 = res.data

          this.$nextTick(() => {
            setTimeout(() => {
              this.drawEchart1()
              this.drawEchart2()
              this.drawEchart3()
              this.drawEchart4()
            }, 0)
          })
        }
      })
    },
    drawEchart1() {
      if (this.chart1) {
        this.chart1.clear()
      } else {
        this.chart1 = this.$echarts?.init(document.getElementById('echarts1'))
      }
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById('box_echarts1'), () => {
        this.chart1.resize()
      })

      let data = []

      this.listData1.forEach(ele => {
        data.push({
          year: ele.year + '年',
          primaryIndustryPercentage: ele.primaryIndustryPercentage?.toFixed(2),
          secondaryIndustryPercentage: ele.secondaryIndustryPercentage?.toFixed(2),
          tertiaryIndustryPercentage: ele.tertiaryIndustryPercentage?.toFixed(2),
          total: (
            ele.primaryIndustryPercentage +
            ele.secondaryIndustryPercentage +
            ele.tertiaryIndustryPercentage
          )?.toFixed(2)
        })
      })

      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        legend: {
          top: '2%',
          left: 'center',
          icon: 'circle',
          textStyle: {
            color: 'black', // 修改图例文字颜色为白色
            fontSize: 11
          }
          // data: ['第一产业', '第二产业', '第三产业','总数']
        },

        xAxis: [
          {
            axisTick: { show: false },
            data: data.map(item => item.year),
            axisLine: {
              show: true, //隐藏X轴轴线
              lineStyle: {
                color: '#7A8595'
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#7A8595' //X轴文字颜色
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '（%）',
            splitLine: {
              show: false
            },
            axisTick: {
              show: true
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#7A8595'
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#7A8595'
              }
            },
            min: 0,
            interval: 20,
            precision: 2
          }
        ],
        series: [
          {
            name: '第一产业',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            barWidth: 12, // 这里设置柱状图的宽度
            data: data.map(item => item.primaryIndustryPercentage),
            stack: 'stack', // 将stack值修改为'stack'
            itemStyle: {
              // 这里设置该系列的颜色
              normal: {
                color: '#3F8CFF',
                borderColor: '#3F8CFF',
                barBorderRadius: 0
              }
            }
          },
          {
            name: '第二产业',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            barWidth: 12, // 这里设置柱状图的宽度
            data: data.map(item => item.secondaryIndustryPercentage),
            stack: 'stack', // 将stack值修改为'stack'
            itemStyle: {
              // 这里设置该系列的颜色
              normal: {
                color: '#0DC1E2',
                barBorderRadius: 0,
                borderColor: '#0DC1E2'
              }
            }
          },
          {
            name: '第三产业',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            barWidth: 12, // 这里设置柱状图的宽度
            data: data.map(item => item.tertiaryIndustryPercentage),
            stack: 'stack', // 将stack值修改为'stack'
            itemStyle: {
              // 这里设置该系列的颜色
              normal: {
                color: '#FFAB00',
                borderColor: '#FFAB00',
                barBorderRadius: 0
              }
            }
          },
          {
            name: '总数',
            type: 'line',
            symbolSize: 10,
            symbol: null, // 设置为空
            // symbol: 'circle',
            itemStyle: {
              normal: {
                color: '#FF5D2F',
                barBorderRadius: 0,
                label: {
                  // show: true,
                  position: 'top',
                  formatter: function(p) {
                    return p.value > 0 ? p.value : ''
                  }
                }
              }
            },
            lineStyle: {
              type: 'dashed' // 设置虚线
            },
            data: data.map(item => item.total)
          }
        ]
      }

      option && this.chart1.setOption(option)
    },
    drawEchart2() {
      if (this.chart2) {
        this.chart2.clear()
      } else {
        this.chart2 = this.$echarts.init(document.getElementById('echarts2'))
      }
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById('box_echarts2'), () => {
        this.chart2.resize()
      })

      let data = []

      this.listData1.forEach(ele => {
        data.push({
          year: ele.year + '年',
          perCapitaHousingArea: ele.perCapitaHousingArea?.toFixed(2)
        })
      })

      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        legend: {
          show: false
        },

        xAxis: [
          {
            // type: 'category',
            data: data.map(ele => ele.year),
            axisTick: { show: true },
            axisLine: {
              show: true, //隐藏X轴轴线
              lineStyle: {
                color: '#7A8595'
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#7A8595' //X轴文字颜色
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '（㎡）',
            nameTextStyle: {
              color: '#7A8595'
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: true
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#7A8595'
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#7A8595'
              }
            }
          }
        ],
        series: [
          {
            // name: '县、市、区',
            type: 'line',
            stack: 'Total',
            smooth: true,
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: data.map(ele => ele.perCapitaHousingArea),
            itemStyle: {
              // 这里设置该系列的颜色
              normal: {
                color: '#3F8CFF',
                borderColor: '#3F8CFF',
                barBorderRadius: 12
              }
            }
          }
        ]
      }

      option && this.chart2.setOption(option)
    },
    drawEchart3() {
      if (this.chart3) {
        this.chart3.clear()
      } else {
        this.chart3 = this.$echarts.init(document.getElementById('echarts3'))
      }
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById('box_echarts3'), () => {
        this.chart3.resize()
      })

      let data = []

      this.listData1.forEach(ele => {
        data.push({
          year: ele.year + '年',
          totalPopulation: ele.totalPopulation?.toFixed(2), //常住人口
          ruralResidentPopulation: ele.ruralResidentPopulation?.toFixed(2), //农村常驻人口
          populationProportion: ele.populationProportion?.toFixed(2), //占全省人口比重
          residentUrbanizationRate: ele.residentUrbanizationRate?.toFixed(2) //占全省人口比重
        })
      })

      let option = {
        grid: {
          top: '25%',
          bottom: '10%' //也可设置left和right设置距离来控制图表的大小
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          }
        },

        legend: {
          top: '2%',
          left: 'center'
        },
        xAxis: {
          data: data.map(ele => ele.year),
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: '#7A8595'
            }
          },
          axisTick: {
            show: true //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7A8595' //X轴文字颜色
            }
          }
        },
        yAxis: [
          {
            type: 'value',
            name: '（万人）',
            min: 0,
            nameTextStyle: {
              color: '#7A8595'
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: true
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#7A8595'
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#7A8595'
              }
            }
          },
          {
            type: 'value',
            name: '（%）',
            nameTextStyle: {
              color: '#7A8595'
            },
            min: 0,
            interval: 20, // 设置刻度间隔为10（可选，根据实际情况）
            max: 100, // 设置最大值为100
            position: 'right',
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              show: true,
              // formatter: '{value} %', //右侧Y轴文字显示
              formatter: '{value}', //右侧Y轴文字显示
              textStyle: {
                color: '#7A8595'
              }
            },
            splitNumber: 8 // 可以根据需要调整刻度间隔
          }
        ],
        series: [
          {
            name: '常驻人口(左轴)',
            type: 'line',
            yAxisIndex: 0, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: true, //平滑曲线显示
            showAllSymbol: true, //显示所有图形。
            symbol: 'circle', //标记的图形为实心圆
            symbolSize: 10, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: '#3F8CFF'
            },
            lineStyle: {
              color: '#3F8CFF'
            },
            data: data.map(ele => ele.totalPopulation)
          },
          {
            name: '农村常驻人口(左轴)',
            type: 'line',
            yAxisIndex: 0, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: true, //平滑曲线显示
            showAllSymbol: true, //显示所有图形。
            symbol: 'circle', //标记的图形为实心圆
            symbolSize: 10, //标记的大小
            itemStyle: {
              normal: {
                color: '#0DC1E2'
              }
            },
            lineStyle: {
              color: '#0DC1E2'
            },
            data: data.map(ele => ele.ruralResidentPopulation)
          },
          {
            name: '占全省人口比重(右轴)',
            type: 'line',
            yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: true, //平滑曲线显示
            showAllSymbol: true, //显示所有图形。
            symbol: 'circle', //标记的图形为实心圆
            symbolSize: 10, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: '#FFAB00'
            },
            lineStyle: {
              color: '#FFAB00'
            },
            data: data.map(ele => ele.populationProportion),
            lineStyle: {
              type: 'dashed' // 设置虚线
            }
          },
          {
            name: '人口城镇比例(右轴)',
            type: 'line',
            yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            barWidth: 15,
            smooth: true, //平滑曲线显示
            showAllSymbol: true, //显示所有图形。
            symbol: 'circle', //标记的图形为实心圆
            symbolSize: 10, //标记的大小
            itemStyle: {
              normal: {
                color: '#FF5D2F'
              }
            },
            lineStyle: {
              color: '#FF5D2F'
            },
            data: data.map(ele => ele.residentUrbanizationRate),
            lineStyle: {
              type: 'dashed' // 设置虚线
            }
          }
        ]
      }

      option && this.chart3.setOption(option)
    },
    drawEchart4() {
      if (this.chart4) {
        this.chart4.clear()
      } else {
        this.chart4 = this.$echarts.init(document.getElementById('echarts4'))
      }
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById('box_echarts4'), () => {
        this.chart4.resize()
      })

      let data = []

      this.listData1.forEach(ele => {
        data.push({
          year: ele.year + '年',
          perCapitaGDP: ele.perCapitaGDP?.toFixed(2), //人均GDP
          perCapitaIncome: ele.perCapitaIncome?.toFixed(2), // 人均可支配收入
          perCapitaArableLand: ele.perCapitaArableLand?.toFixed(2) //人均耕地
        })
      })

      let option = {
        grid: {
          top: '25%',
          bottom: '10%' //也可设置left和right设置距离来控制图表的大小
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          }
        },

        legend: {
          top: '2%',
          left: 'center'
        },
        xAxis: {
          data: ['2021年', '2022年'],
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: '#7A8595'
            }
          },
          axisTick: {
            show: true //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#7A8595' //X轴文字颜色
            }
          }
        },
        yAxis: [
          {
            type: 'value',
            name: '（元）',
            nameTextStyle: {
              color: '#7A8595'
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: true
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#7A8595'
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#7A8595'
              }
            }
          },
          {
            type: 'value',
            name: '（亩）',
            nameTextStyle: {
              color: '#7A8595'
            },
            min: 0,
            // max:100,
            // interval: 20,
            position: 'right',
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              show: true,
              // formatter: '{value} %', //右侧Y轴文字显示
              formatter: '{value}', //右侧Y轴文字显示
              textStyle: {
                color: '#7A8595'
              }
            }
          }
        ],
        series: [
          {
            name: '人均GDP(左轴)',
            type: 'line',
            yAxisIndex: 0, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: true, //平滑曲线显示
            showAllSymbol: true, //显示所有图形。
            symbol: 'circle', //标记的图形为实心圆
            symbolSize: 10, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: '#3F8CFF'
            },
            lineStyle: {
              color: '#3F8CFF'
            },
            data: data.map(ele => ele.perCapitaGDP)
          },
          {
            name: '人均可支配收入(左轴)',
            type: 'line',
            yAxisIndex: 0, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: true, //平滑曲线显示
            showAllSymbol: true, //显示所有图形。
            symbol: 'circle', //标记的图形为实心圆
            symbolSize: 10, //标记的大小
            itemStyle: {
              normal: {
                color: '#0DC1E2'
              }
            },
            lineStyle: {
              color: '#0DC1E2'
            },
            data: data.map(ele => ele.perCapitaIncome)
          },
          {
            name: '人均耕地(右轴)',
            type: 'line',
            yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: true, //平滑曲线显示
            showAllSymbol: true, //显示所有图形。
            symbol: 'circle', //标记的图形为实心圆
            symbolSize: 10, //标记的大小
            itemStyle: {
              normal: {
                color: '#FFAB00'
              }
            },
            lineStyle: {
              color: '#FFAB00'
            },
            data: data.map(ele => ele.perCapitaArableLand),
            lineStyle: {
              type: 'dashed' // 设置虚线
            }
          }
        ]
      }

      option && this.chart4.setOption(option)
    },
    exportExcel() {
      let params = {
        year: this.year
      }
      _PopulationExport(params).then(res => {
        if (res.status == 200) {
          this.downloadfile(res)
        } else {
          this.$message.error('导出失败')
        }
      })
    },
    importExcel() {
      // 首页1不需要year,根据导入文件中的年份导入
      this.$refs.tempalteImport.view('', '', '1-1-1')
    },
    downloadfile(res) {
      var contentDisposition = res.headers['content-disposition']
      if (contentDisposition) {
        const regex = /filename*.+?\.xlsx/g
        const matches = contentDisposition.match(regex)
        const filename1 = matches[1].replace(/['"]/g, '')
        const startIndex = filename1.indexOf('filename*=UTF-8') + 'filename*=UTF-8'.length
        const result = filename1.substring(startIndex)
        const filename = result.replace(/^_+/g, '')
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(res.data) // 创建下载的链接
        var reg = /^["](.*)["]$/g
        downloadElement.style.display = 'none'
        downloadElement.href = href
        downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href)
      } else {
        this.$message.error('导出失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-row,
.el-col {
  border: none;
}

.box_top {
  display: flex;
  justify-content: space-between;
}
.box_item1 {
  width: 98%;
  // height: 120px;
  padding: 8px 18px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #eef4ff 0%, #fbfcff 100%);
  // background: #bfc;
  font-family: PingFang SC;
  & > .box_item1_list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;

    & > .item1_list_left {
      font-size: 14px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0em;
      color: #7a8595;
    }
    & > .item1_list_right {
      font-size: 18px;
      font-weight: bold;
      line-height: 18px;
      text-align: right;
      letter-spacing: 0em;
      color: #1d2129;
    }
  }
}

.box_item2 {
  width: 98%;
  margin-top: 10px;
}

.box_item3 {
  width: 98%;
  margin-top: 10px;
}

#box_echarts1,
#box_echarts2,
#box_echarts3,
#box_echarts4 {
  & > #echarts1,
  & > #echarts2,
  & > #echarts3,
  & > #echarts4 {
    width: 100%;
    height: 230px;
  }
}
</style>
